import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.1_react-dom@19.0.0-rc-69d4b800-20241021_react@19.0.0-rc-69d4b800-20241021__react@19.0.0-rc-69d4b800-20241021/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.1_react-dom@19.0.0-rc-69d4b800-20241021_react@19.0.0-rc-69d4b800-20241021__react@19.0.0-rc-69d4b800-20241021/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.1_react-dom@19.0.0-rc-69d4b800-20241021_react@19.0.0-rc-69d4b800-20241021__react@19.0.0-rc-69d4b800-20241021/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.1_react-dom@19.0.0-rc-69d4b800-20241021_react@19.0.0-rc-69d4b800-20241021__react@19.0.0-rc-69d4b800-20241021/node_modules/next/font/google/target.css?{\"path\":\"../../packages/ui/components/website/web-root-layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\",\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/public/images/logo-color.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/public/images/logo-text.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/public/images/logo-white.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/styles/web-globals.css");
